import React, { createContext, useState, useMemo, useEffect } from 'react';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';

// 创建 Settings 上下文
export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  // 从 localStorage 初始化语言和主题模式
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'en'; // 如果没有存储的语言，默认为 'en'
  });
  const [themeMode, setThemeMode] = useState(() => {
    return localStorage.getItem('themeMode') || 'light'; // 如果没有存储的主题模式，默认为 'light'
  });

  // 检测系统语言
  useEffect(() => {
    if (!localStorage.getItem('language')) {
      const systemLanguage = navigator.language.startsWith('zh') ? 'cn' : 'en'; // 检测是否是中文环境
      setLanguage(systemLanguage);
      localStorage.setItem('language', systemLanguage); // 保存到 localStorage
    }
  }, []);

  // 检测系统主题
  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)'); // 检测系统是否为暗色模式
    if (!localStorage.getItem('themeMode')) {
      const systemThemeMode = darkModeMediaQuery.matches ? 'dark' : 'light';
      setThemeMode(systemThemeMode);
      localStorage.setItem('themeMode', systemThemeMode); // 保存到 localStorage
    }

    // 监听系统主题变化
    const handleChange = (e) => {
      const newThemeMode = e.matches ? 'dark' : 'light';
      setThemeMode(newThemeMode);
      localStorage.setItem('themeMode', newThemeMode); // 保存到 localStorage
    };
    darkModeMediaQuery.addEventListener('change', handleChange);

    // 清除事件监听
    return () => {
      darkModeMediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  // 切换语言
  const toggleLanguage = () => {
    const newLanguage = language === 'en' ? 'cn' : 'en';
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage); // 保存到 localStorage
  };

  // 切换主题模式
  const toggleTheme = () => {
    const newThemeMode = themeMode === 'light' ? 'dark' : 'light';
    setThemeMode(newThemeMode);
    localStorage.setItem('themeMode', newThemeMode); // 保存到 localStorage
  };

  // 动态创建 Material-UI 主题
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode, // 应用当前主题模式
          background: {
            default: themeMode === 'light' ? '#f5f5f5' : '#1a1a1a', // 设置全局背景颜色
            paper: themeMode === 'light' ? '#f8f8f8' : '#181818', // 卡片背景颜色
          },
        },
      }),
    [themeMode]
  );

  return (
    <SettingsContext.Provider value={{ language, themeMode, toggleLanguage, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </SettingsContext.Provider>
  );
};
