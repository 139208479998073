// AppRoutes.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Blogs from '../pages/Blogs';
import Notes from '../pages/Notes';
import MarkdownPage from '../pages/MarkdownPage';
import Finance from '../pages/Finance';
import Signup from '../pages/Signup';
import Profile from '../pages/Profile';
import Projects from '../pages/Projects';
import AddBlogPage from '../pages/AddBlogPage';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/notes" element={<Notes />} />
        <Route path="/notes/:filePath" element={<MarkdownPage />} />
        <Route path="/Notes/:filePath" element={<MarkdownPage />} />
        <Route path="/fin" element={<Finance />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/addblog" element={<AddBlogPage />} />
        <Route path="/terms/:language" element={<MarkdownPage />} />
        <Route path="/privacy/:language" element={<MarkdownPage />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
