import axios from 'axios';
import BASE_URL from "../config"; // 引入后端地址配置

const api = axios.create({
  baseURL: BASE_URL, // 你的后端地址
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;
