import React, { useContext, useState } from "react";
import { Box, Tabs, Tab, TextField, Button } from "@mui/material";
import CustomAppBar from "../components/AppBar";
import DraftJsWysiwyg from "../components/Draft-js-wysiwyg";
import { SettingsContext } from "../context/SettingsContext";
import { EditorState, convertToRaw } from "draft-js";

const AddBlogPage = () => {
  const { themeMode, language } = useContext(SettingsContext);
  const [activeTab, setActiveTab] = useState(0);
  const [tabsData, setTabsData] = useState({
    CN: { title: "", content: EditorState.createEmpty() },
    EN: { title: "", content: EditorState.createEmpty() },
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleInputChange = (langKey, field, value) => {
    setTabsData((prev) => ({
      ...prev,
      [langKey]: { ...prev[langKey], [field]: value },
    }));
  };

  const handleContentChange = (langKey, editorState) => {
    setTabsData((prev) => ({
      ...prev,
      [langKey]: { ...prev[langKey], content: editorState },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSubmit = {
      CN: {
        title: tabsData.CN.title,
        content: JSON.stringify(
          convertToRaw(tabsData.CN.content.getCurrentContent())
        ),
      },
      EN: {
        title: tabsData.EN.title,
        content: JSON.stringify(
          convertToRaw(tabsData.EN.content.getCurrentContent())
        ),
      },
    };
    console.log("Submitted data:", dataToSubmit);

    setTabsData({
      CN: { title: "", content: EditorState.createEmpty() },
      EN: { title: "", content: EditorState.createEmpty() },
    });
  };

  const labels = {
    title: language === "cn" ? "标题" : "Title",
    content: language === "cn" ? "内容" : "Content",
    submit: language === "cn" ? "提交" : "Submit",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: themeMode?.palette?.background?.default || "#f5f5f5",
      }}
    >
      <CustomAppBar />
      <Box
        sx={{
          flex: "1 0 auto",
          display: "flex",
          flexDirection: "column",
          maxWidth: 1000,
          width: "100%",
          mt: 12,
          // px: 2,
          // py: 4,
          mx: "auto",
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ mb: 3 }}
          variant="fullWidth"
        >
          <Tab label="CN" />
          <Tab label="EN" />
        </Tabs>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            label={activeTab === 0 ? `${labels.title} (CN)` : `${labels.title} (EN)`}
            variant="outlined"
            value={activeTab === 0 ? tabsData.CN.title : tabsData.EN.title}
            onChange={(e) =>
              handleInputChange(
                activeTab === 0 ? "CN" : "EN",
                "title",
                e.target.value
              )
            }
            fullWidth
            sx={{ mb: 3 }}
          />

          <DraftJsWysiwyg
            editorState={activeTab === 0 ? tabsData.CN.content : tabsData.EN.content}
            onEditorStateChange={(editorState) =>
              handleContentChange(activeTab === 0 ? "CN" : "EN", editorState)
            }
            placeholder={labels.content}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Button type="submit" variant="contained" color="primary">
              {labels.submit}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddBlogPage;