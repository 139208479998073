// ./services/userService.js
import axios from 'axios';
import userStore from '../store/userStore';
import api from './api'; // 引入共享的 axios 实例

// Service 函数
const userService = {
  // 注册新用户
  register: async (username, password, inviteCode) => {
    const data = { username, password, invite_code: inviteCode };
    const response = await api.post('/register/', data);
    return response;
  },

  // 获取用户列表（仅管理员）
  getUsers: async (token) => {
    const response = await api.get('/users/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  },

  // 获取用户详情
  getUserDetail: async (userId, token) => {
    const response = await api.get(`/users/${userId}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  },

  // 登录
  login: async (username, password) => {
    const data = { username, password };
    const response = await api.post('/login/', data);
    if (response.status === 200) {
      const data = response.data;
      userStore.setUser(data);
    }
    return response;
  },

  // 刷新 Token
  refreshToken: async (refreshToken) => {
    try{
      const data = { refresh: refreshToken };
      const response = await api.post('/token/refresh/', data);
      // 获取匿名用户 JWT
      if (response.status === 200) {
        const data = response.data;
        userStore.setUser(data);
        // console.log("User data has been set:", userStore.getUser());
      } else {
        console.error("Failed to initialize app. Status:", response.status);
      }
    } catch (error) {
      console.error("Error while initializing app:", error);
    }
  },

  // 生成邀请码（仅管理员）
  generateInviteCode: async (token) => {
    const response = await api.post('/generate-invite-code/', {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  },

  // 获取邀请码列表（仅管理员）
  getInviteCodes: async (token) => {
    const response = await api.get('/invite-codes/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  },

  // 获取匿名用户的 JWT
  getNonuserJWT: async () => {
    try{
        const response = await api.get('/nonuser/');
        // 获取匿名用户 JWT
        if (response.status === 200) {
          const data = response.data;
          userStore.setUser(data);
          console.log("User data has been set:", userStore.getUser());
        } else {
          console.error("Failed to initialize app. Status:", response.status);
        }
      } catch (error) {
        console.error("Error while initializing app:", error);
      }
  },

  // 解码 JWT
  decodeJWT: async (token) => {
    const response = await api.post('/decode-jwt/', {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  },


};

export default userService;

// 如何使用
// import authService from './services/authService';

// // 示例：注册新用户
// authService.register('username', 'password', 'inviteCode')
//   .then(response => console.log(response))
//   .catch(error => console.error(error));

// // 示例：登录
// authService.login('username', 'password')
//   .then(response => console.log(response))
//   .catch(error => console.error(error));

// // 示例：获取匿名用户的 JWT
// authService.getNonuserJWT()
//   .then(response => console.log(response))
//   .catch(error => console.error(error));
