import Cookies from 'js-cookie';
import userService from '../services/userService';
import { useScrollTrigger } from '@mui/material';

// Base64 解码函数
const base64Decode = (str) => {
  try {
    return JSON.parse(atob(str));
  } catch (e) {
    console.error("Failed to decode base64:", e);
    return null;
  }
};

const userStore = {
  state: {
    access: null,
    refresh: null,
    userId: 0,
    username: "",
    isSuperuser: false,
    isStaff: false,
    isUser: false,
    cookies: {},
  },

  setUser(data) {
    this.state.access = data.access;
    this.state.refresh = data.refresh;
    this.state.userId = data.user_id;
    this.state.username = data.username;
    this.state.isSuperuser = data.is_superuser;
    this.state.isStaff = data.is_staff;
    this.state.isUser = data.is_user;

    // 保存必要数据到 cookie
    Cookies.set('refresh', data.refresh, { expires: 7 });
    Cookies.set('userId', data.user_id, { expires: 7 });
    Cookies.set('isUser', data.is_user, { expires: 7 });

    // 更新 state 中的 cookies 数据
    this.state.cookies = {
      refresh: Cookies.get('refresh'),
      userId: Cookies.get('userId'),
      isUser: Cookies.get('isUser'),
    };

  },

  getUser() {
    return this.state;
  },

  async initializeFromCookies() {
    // 从 cookie 中加载数据
    const refresh = Cookies.get('refresh');
    if (!refresh) {
      console.error("No refreshToken found in cookies.");
      await userService.getNonuserJWT();
      return false;
    }

    // 分割 JWT，解析中间部分
    const jwtParts = refresh.split('.');
    if (jwtParts.length !== 3) {
      console.error("Invalid JWT format.");
      await userService.getNonuserJWT();
      return false;
    }

    const payload = base64Decode(jwtParts[1]);
    if (!payload) {
      console.error("Failed to decode JWT payload.");
      await userService.getNonuserJWT();
      return false;
    }

    // 检查 exp 字段是否过期
    const currentTime = Math.floor(Date.now() / 1000);
    if (payload.exp && currentTime >= payload.exp) {
      console.log("Refresh token has expired. Fetching non-user JWT...");
      await userService.getNonuserJWT();
      return true
    } else {
      console.log("Refresh token is valid. Refreshing token...");
      await userService.refreshToken(refresh);
      return true
    }

    return false
  },
};

export default userStore;
