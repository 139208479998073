import React, { useContext } from 'react';
import { Box, Container } from '@mui/material';
import { SettingsContext } from '../context/SettingsContext';
import CustomAppBar from '../components/AppBar';
import ProfileCard from "../components/ProfileCard";
import Footer from '../components/Footer';
import HomeCard from '../components/HomeCard';
import BlogCard from '../components/BlogCard';
import MarkdownViewer from "../components/MarkdownViewer";

const Home = () => {
  const { language, themeMode, toggleLanguage, toggleTheme } = useContext(SettingsContext);

  // 根据 themeMode 设置 filePath
  const filePath = language === "en" ? "md/luoxisteven_en.md" : "md/luoxisteven_cn.md";

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* AppBar 部分 */}
      <CustomAppBar />

      <Box
        sx={{
          marginTop: '64px', // 避免与 AppBar 重叠
          minHeight: 'calc(100vh - 64px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >

        {/* 主内容部分 - 桌面端 */}
        <Container
          maxWidth="lg"
          sx={{
            display: { xs: 'none', md: 'flex' }, // 仅桌面端显示
            alignItems: 'center', // 确保子元素在 Y 轴居中
            justifyContent: 'space-between',
            minHeight: 'calc(100vh - 64px)', // 减去 AppBar 的高度
          }}
        >
          {/* ProfileCard 部分 */}
          <Box
            sx={{
              flex: '1 1 20%',
              display: 'flex',
              alignItems: 'center', // 子项 Y 轴居中
            }}
          >
            <ProfileCard />
          </Box>

          {/* MarkdownViewer 部分 */}
          <Box
            sx={{
              flex: '1 1 80%',
              display: 'flex',
              overflow: 'auto', // 添加滚动行为
              alignItems: 'center', // 子项 Y 轴居中
            }}
          >
            <MarkdownViewer filePath={filePath} />
          </Box>
        </Container>

        {/* 主内容部分 - 移动端 */}
        <Container
          maxWidth="sm"
          sx={{
            display: { xs: 'flex', md: 'none' }, // 仅移动端显示
            flexDirection: 'column', // 垂直堆叠
            alignItems: 'center', // 确保子元素在 X 轴居中
            minHeight: 'calc(100vh - 64px)', // 减去 AppBar 的高度
            marginTop: '64px', // 为移动端内容添加顶部间距
          }}
        >
          {/* ProfileCard 部分 */}
          <Box
            sx={{
              width: '100%', // 占满宽度
              display: 'flex',
              justifyContent: 'center', // 居中对齐
            }}
          >
            <ProfileCard />
          </Box>

          {/* MarkdownViewer 部分 */}
          <Box
            sx={{
              width: '100%', // 占满宽度
              display: 'flex',
              justifyContent: 'center', // 居中对齐
            }}
          >
            <MarkdownViewer filePath={filePath} />
          </Box>
        </Container>

        {/* 添加一个限制宽度的容器 */}
        <Container
          maxWidth="lg"
          sx={{
            minHeight: '1px',
            borderTop: `1px solid ${themeMode === 'light' ? '#ccc' : '#555'}`, // 根据 themeMode 设置线条颜色
          }}
        >
        </Container>

      </Box>
    </Box>
  );
};

export default Home;
