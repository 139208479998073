import React, { useEffect, useState, useContext } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { SettingsContext } from '../context/SettingsContext'; // 引入 SettingsContext
import axios from "axios";
import BASE_URL from "../config"; // 引入后端地址配置

// Markdown
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import remarkGfm from "remark-gfm"; 
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import rehypeRaw from "rehype-raw"; 
import rehypeHighlight from "rehype-highlight"; 


// 导入code样式字符串
import vsdark from '../styles/vsdark';
import vslight from '../styles/vslight';

// 目录
import remarkSlug from "remark-slug"; // 引入 remark-slug
import remarkToc from "remark-toc"; // 引入 remark-toc
import rehypeAutolinkHeadings from "rehype-autolink-headings"; // 引入 rehype-autolink-headings


const MarkdownViewer = ({ filePath, isFromNotePage = false }) => {
  const [markdownContent, setMarkdownContent] = useState("");
  const { themeMode , language } = useContext(SettingsContext); // 获取当前语言和主题

  useEffect(() => {
    const loadMarkdown = async () => {
      try {
        if (isFromNotePage) {
          
          // 创建 FormData 对象
          let sanitizedFilePath = filePath;

          // 检查并移除开头的斜杠
          if (filePath.startsWith("/")) {
            sanitizedFilePath = filePath.substring(1);
          }

          const formData = new FormData();
          formData.append("md_path", sanitizedFilePath);

          // 获取 Markdown 内容
          const mdFormData = new FormData();
          mdFormData.append("md_path", sanitizedFilePath);

          const mdResponse = await axios.post(`${BASE_URL}/notes/get_md/`, mdFormData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          setMarkdownContent(mdResponse.data);


        } else {
          const response = await axios.get(filePath);
          setMarkdownContent(response.data)
        }
      } catch (error) {
        if (language === "cn") {
          setMarkdownContent("无法加载指定的 Markdown 文件。");
        } else {
          setMarkdownContent("The specified Markdown file could not be loaded.");
        }
        console.error(error);
      }
    };
    if (filePath) {
      loadMarkdown();
    }
  }, [filePath, isFromNotePage]);

  useEffect(() => {
    // 动态创建或更新样式标签
    const styleTagId = 'code-theme-styles';
    let styleTag = document.getElementById(styleTagId);

    if (!styleTag) {
      styleTag = document.createElement('style');
      styleTag.type = 'text/css';
      styleTag.id = styleTagId;
      document.head.appendChild(styleTag);
    }

    if (themeMode === 'dark') {
      styleTag.innerHTML = vsdark;
    } else {
      styleTag.innerHTML = vslight;
    }
  }, [themeMode]);
  
  return (
    <Box>
      <Box>
        {markdownContent ? (
          <ReactMarkdown
            children={markdownContent}
            urlTransform={(url, children, title) => {
              if (title.tagName === 'img') {
                return url.startsWith("http") || url.startsWith("https") ? url : `${BASE_URL}/${url}`
              }
              return url; // 对其他 URL 保持原样
            }}
            remarkPlugins={[remarkMath, remarkGfm, remarkSlug, remarkToc]}
            rehypePlugins={[rehypeKatex, rehypeRaw, rehypeHighlight, rehypeAutolinkHeadings]}
            // skipHtml={false} // 如果 Markdown 包含 HTML，可以尝试设置为 true 测试冲突
            components={{
              h1: ({ node, ...props }) => (
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ 
                    fontWeight: 600, 
                    lineHeight: 1.5, 
                    marginBottom: 2,
                    pb: 2,
                    borderBottom: "2px solid", // 添加底部横线
                    borderColor: themeMode === "dark" ? "#888" : "#aaa", // 根据主题模式设置颜色
                  }}
                  {...props}
                />
              ),
              h2: ({ node, ...props }) => (
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ 
                    fontWeight: 600, 
                    lineHeight: 1.5,
                    pb: 1.5,
                    marginTop: 3,
                    marginBottom: 1.5,
                    borderBottom: "2px solid", // 添加底部横线
                    borderColor: themeMode === "dark" ? "#555" : "#ccc", // 根据主题模式设置颜色
                  }}
                  {...props}
                />
              ),
              h3: ({ node, ...props }) => (
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{ fontWeight: 600, lineHeight: 1.4, marginBottom: 1.2 }}
                  {...props}
                />
              ),
              p: ({ node, ...props }) => (
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    fontSize: "0.9rem",
                    lineHeight: 1.6,
                    marginBottom: 1.5,
                  }}
                  {...props}
                />
              ),
              li: ({ node, ...props }) => (
                <Typography
                  component="li"
                  variant="body2"
                  gutterBottom
                  sx={{
                    fontSize: "0.9rem",
                    lineHeight: 1.6,
                    marginBottom: 0.5,
                  }}
                  {...props}
                />
              ),
              a: ({ node, ...props }) => {
                const isExternalLink = props.href.startsWith("http://") || props.href.startsWith("https://");
                return (
                  <Typography
                    component="a"
                    variant="body2"
                    sx={{
                      color: themeMode === "dark" ? "#64b5f6" : "#13479c",
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    target={isExternalLink ? "_blank" : "_self"} // 外部链接新标签页打开，内部链接当前页面跳转
                    rel={isExternalLink ? "noopener noreferrer" : undefined} // 外部链接安全增强
                    {...props}
                  />
                );
              },
              img: ({ node, ...props }) => (
                <Box
                  component="img"
                  sx={{
                    maxWidth: { xs: "100%" , md: "85%" }, // 限制图片最大宽度不超过容器
                    height: "auto",   // 保持图片的纵横比
                    display: "block", // 将图片设为块级元素，避免文字围绕图片
                    margin: "0 auto"  // 居中图片
                  }}
                  {...props}
                />
              ),
              table: ({ node, ...props }) => (
                <Box
                  component="table"
                  sx={{
                    margin: "0 auto",
                    borderCollapse: "collapse",
                    maxWidth: {xs: "100%" , md: "85%"},
                    width: "100%",
                    overflowX: "auto",
                  }}
                  {...props}
                />
              ),
              thead: ({ node, ...props }) => (
                <Box
                  component="thead"
                  sx={{
                    borderBottom: "2px solid",
                    borderColor: themeMode === "dark" ? "#555" : "#ccc",
                  }}
                  {...props}
                />
              ),
              tr: ({ node, ...props }) => (
                <Box
                  component="tr"
                  sx={{
                    borderBottom: "1px solid",
                    borderColor: themeMode === "dark" ? "#444" : "#ddd",
                  }}
                  {...props}
                />
              ),
              th: ({ node, ...props }) => (
                <Box
                  component="th"
                  sx={{
                    padding: "8px",
                    textAlign: "center",
                    fontWeight: "bold",
                    borderBottom: "2px solid",
                    borderColor: themeMode === "dark" ? "#555" : "#ccc",
                  }}
                  {...props}
                />
              ),
              td: ({ node, ...props }) => (
                <Box
                  component="td"
                  sx={{
                    padding: "8px",
                    textAlign: "center",
                    borderBottom: "1px solid",
                    borderColor: themeMode === "dark" ? "#444" : "#ddd",
                  }}
                  {...props}
                />
              ),
              code: ({ node, inline, ...props }) => (
                <Box
                component="code"
                sx={{
                  backgroundColor: themeMode === "dark" ? "#353535": "#E7E7E7", 
                  fontFamily: "'Menlo', 'Consolas', 'Courier New', monospace",
                }}
                {...props}
              />
              ),
              
            }}
          />
        ) : (
        <Typography variant="body2" color="textSecondary">
          {language === "cn" ? "正在加载内容..." : "Loading content..."}
        </Typography>
        )}
      </Box>
    </Box>
  );
};

export default MarkdownViewer;
