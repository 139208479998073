import React from 'react';
import { Container, Card, CardContent, Typography, Grid } from '@mui/material';

// Blog 数据样例
const blogs = [
  {
    title: 'Test1',
    description: 'Learn the basics of Material-UI and how to create stunning components.',
    publishedAt: '2024-12-01',
    author: 'Steve Luo',
  },
  {
    title: 'Test2',
    description: 'A comprehensive guide to React hooks and how to use them effectively.',
    publishedAt: '2024-12-03',
    author: 'Jane Doe',
  },
  {
    title: 'Test3',
    description: 'Enhance your frontend development skills with these practical tips.',
    publishedAt: '2024-12-05',
    author: 'John Smith',
  },
];

const BlogCard = ({ sx }) => {
    return (
      <Container maxWidth="md" sx={{ ...sx }}>
        <Grid container spacing={2} direction="column">
          {blogs.map((blog, index) => (
            <Grid item key={index}>
              <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 2 }}>
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    {blog.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {blog.description}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Published on {blog.publishedAt} by {blog.author}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  };

export default BlogCard;
