import React, { useContext } from 'react';
import { Box, Fab, Container } from '@mui/material';
import CustomAppBar from '../components/AppBar';
import { SettingsContext } from '../context/SettingsContext'; // 引入 SettingsContext
import BlogCard from '../components/BlogCard';
import AddIcon from '@mui/icons-material/Add'; // 引入 AddIcon
import { useNavigate } from 'react-router-dom'; // 引入 useNavigate

const Blogs = () => {
  const { language, themeMode, toggleLanguage, toggleTheme } = useContext(SettingsContext); // 获取当前语言
  const navigate = useNavigate(); // 初始化 navigate

  const handleAddBlog = () => {
    navigate('/addblog'); // 跳转到 AddBlogPage
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CustomAppBar />
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: "100px",
        }}
      >
        <BlogCard />
      </Container>

      {/* 右下角固定按钮 */}
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleAddBlog} // 绑定点击事件
        sx={{
          position: 'fixed', // 页面固定位置
          bottom: '50px', // 距离页面底部
          right: 'calc((100% - 1200px) / 2 + 20px)', // 居中区域 + 距离右侧 20px
          '@media (max-width: 1280px)': {
            right: '30px', // 小屏幕直接靠右
          },
        }}
      >
        <AddIcon />
      </Fab>

    </Box>
  );
};

export default Blogs;
