const vslight = `
.hljs {
  display: block;
  overflow-x: auto;
  background: #F8F9F8;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  padding: 1.5rem; /* 设置内边距为 1rem */
  line-height: 1.4; /* 设置行高为 1.4 */
  font-size: 0.9rem; /* 设置字体大小为 0.9rem */
  font-family: 'Menlo', 'Consolas', 'Courier New', monospace;z
}
.hljs-comment, .hljs-quote, .hljs-variable {
  color: green;
}
.hljs-built_in, .hljs-keyword, .hljs-name, .hljs-selector-tag, .hljs-tag {
  color: #00f;
}
.hljs-addition, .hljs-attribute, .hljs-literal, .hljs-section, .hljs-string, .hljs-template-tag, .hljs-template-variable, .hljs-title, .hljs-type {
  color: #a31515;
}
.hljs-deletion, .hljs-meta, .hljs-selector-attr, .hljs-selector-pseudo {
  color: #2b91af;
}
.hljs-doctag {
  color: grey;
}
.hljs-attr {
  color: red;
}
.hljs-bullet, .hljs-link, .hljs-symbol {
  color: #00b0e8;
}
.hljs-emphasis {
  font-style: italic;
}
.hljs-strong {
  font-weight: 700;
}
`;

export default vslight;
