import React, { useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom'; // 确保从 react-router-dom 导入 useParams
import { Box, Container } from '@mui/material';
import CustomAppBar from '../components/AppBar';
import { SettingsContext } from '../context/SettingsContext'; // 引入 SettingsContext
import Footer from '../components/Footer';
import MarkdownViewer from "../components/MarkdownViewer";

const MarkdownPage = () => {
  const { filePath }  = useParams();
  const { pathname } = useLocation();
  const { themeMode } = useContext(SettingsContext); // 从 SettingsContext 获取 themeMode

  if (!pathname) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <p>Invalid file path. Please check the URL.</p>
      </Box>  
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      {/* 顶部导航 */}
      <CustomAppBar />

      {/* 主内容区域 */}
      <Box
        sx={{
          flex: 1, // 占满剩余空间
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start', // 内容从顶部开始
          mt: { xs: 2, md: 12}, // 与导航栏分隔
          px: { xs: 2, sm: 4, md: 6 }, // 响应式内边距
          overflow: 'auto', // 滚动行为
        }}
      >
        <Box
          sx={{
            maxWidth: '900px', // 最大宽度
            width: '100%', // 自适应宽度
            backgroundColor: 'background.paper', // 内容背景色
            borderRadius: 5, // 圆角
            p: "5%",  
            mt: { xs: 10, md: 0 },
            overflow: 'auto', // 添加滚动
            borderTop: `1px solid ${themeMode === 'light' ? '#ccc' : '#555'}`, // 根据 themeMode 设置线条颜色
            borderBottom: `1px solid ${themeMode === 'light' ? '#ccc' : '#555'}`, // 根据 themeMode 设置线条颜色
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1 )', // 扩展范围让阴影更明显
          }}
        >
          {/* 渲染 Markdown 文件 */}
          {/* <MarkdownViewer filePath={"Notes/"+filePath} isFromNotePage={true} /> */}
          <MarkdownViewer filePath={pathname} isFromNotePage={true} />
        </Box>
      </Box>

      {/* 页脚 */}
      {/* <Container
        sx={{
          mt: 1, // 上下内边距
          textAlign: 'center',
        }}
      >
        <Footer />
      </Container> */}
    </Box>
  );
};

export default MarkdownPage;
