import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import CustomAppBar from '../components/AppBar';
import { SettingsContext } from '../context/SettingsContext'; // 引入 SettingsContext
import Footer from '../components/Footer';

const Finance = () => {
  const { language, themeMode, toggleLanguage, toggleTheme } = useContext(SettingsContext); // 获取当前语言

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* 上部 100vh 的部分 */}
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CustomAppBar />
        <Box sx={{ mt: 8, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            {language === 'en' ? (
              <>
                Finance! <br />
                Still Working on it!
              </>
            ) : (
              <>
                金融面板！ <br />
                再等等，还在弄，别催！
              </>
            )}
          </Typography>
        </Box>
      </Box>

      {/* 下部 Footer */}
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          py: 2, // 上下内边距
          backgroundColor: 'background.default',
        }}
      >
        <Footer />
      </Box> */}
    </Box>
  );
};

export default Finance;
