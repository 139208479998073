const vsdark = `
.hljs {
  display: block;
  overflow-x: auto;
  background: #1a1a1a;
  color: #dcdcdc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  padding: 1.5rem; /* 设置内边距为 1rem */
  line-height: 1.4; /* 设置行高为 1.4 */
  font-size: 0.9rem; /* 设置字体大小为 0.9rem */
  font-family: 'Menlo', 'Consolas', 'Courier New', monospace;z
}
.hljs-keyword, .hljs-literal, .hljs-name, .hljs-symbol {
  color: #569cd6;
}
.hljs-link {
  color: #569cd6;
  text-decoration: underline;
}
.hljs-built_in, .hljs-type {
  color: #4ec9b0;
}
.hljs-class, .hljs-number {
  color: #b8d7a3;
}
.hljs-meta-string, .hljs-string {
  color: #d69d85;
}
.hljs-regexp, .hljs-template-tag {
  color: #9a5334;
}
.hljs-formula, .hljs-function, .hljs-params, .hljs-subst, .hljs-title {
  color: #dcdcdc;
}
.hljs-comment, .hljs-quote {
  color: #57a64a;
  font-style: italic;
}
.hljs-doctag {
  color: #608b4e;
}
.hljs-meta, .hljs-meta-keyword, .hljs-tag {
  color: #9b9b9b;
}
.hljs-template-variable, .hljs-variable {
  color: #bd63c5;
}
.hljs-attr, .hljs-attribute, .hljs-builtin-name {
  color: #9cdcfe;
}
.hljs-section {
  color: gold;
}
.hljs-emphasis {
  font-style: italic;
}
.hljs-strong {
  font-weight: 700;
}
.hljs-bullet, .hljs-selector-attr, .hljs-selector-class, .hljs-selector-id, .hljs-selector-pseudo, .hljs-selector-tag {
  color: #d7ba7d;
}
.hljs-addition {
  background-color: #144212;
  display: inline-block;
  width: 100%;
}
.hljs-deletion {
  background-color: #600;
  display: inline-block;
  width: 100%;
}
`;

export default vsdark;
