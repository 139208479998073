import React, { useContext, useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  IconButton,
  Container,
  Divider,
  Menu,
  MenuItem,
  Drawer,
  Avatar,
  Modal,
  Backdrop,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { SettingsContext } from '../context/SettingsContext';
import ThemeSwitch from './ThemeSwitch';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import LanguageSwitchComponent from './LanguageSwitch';
import userStore from '../store/userStore';
import userService from '../services/userService';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: (theme.vars || theme).palette.divider,
  backgroundColor: theme.vars
    ? `rgba(${theme.vars.palette.background.defaultChannel} / 0.4)`
    : alpha(theme.palette.background.default, 0.4),
  boxShadow: (theme.vars || theme).shadows[1],
  padding: '8px 12px',
}));

const translations = {
  en: {
    home: 'Home',
    blogs: 'Blogs',
    notes: 'Notes',
    projects: 'Projects',
    finance: 'Finance',
    profile: 'Profile',
    login: 'Log in',
    logout: 'Log out',
    signup: 'Sign up',
    password_reset: 'Reset Password',
  },
  cn: {
    home: '主页',
    blogs: '博客',
    notes: '笔记',
    projects: '项目',
    finance: '金融面板',
    profile: '个人简介',
    login: '登录',
    logout: '退出登录',
    signup: '注册',
    password_reset: '修改密码',
  },
};

export default function CustomAppBar() {
  const { language, toggleLanguage, themeMode, toggleTheme } = useContext(SettingsContext);
  const [open, setOpen] = useState(false);

  // 下拉菜单状态
  const [anchorEl, setAnchorEl] = useState(false); 

  // Login Trigger
  const [loginOpen, setLoginOpen] = useState(false);
  const handleLoginOpen = () => setLoginOpen(true);
  const handleLoginClose = () => setLoginOpen(false);

  // Signup Trigger
  const [signupOpen, setSignupOpen] = useState(false);
  const handleSignupOpen = () => setSignupOpen(true);
  const handleSignupClose = () => setSignupOpen(false);


  // User 钩子
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const initialize = async () => {
      await userStore.initializeFromCookies();
      setUserId(userStore.state.userId); // 同步 userStore 的 userId
    };
    initialize();
  }, []);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="absolute"
      enableColorOnDark
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 'calc(var(--template-frame-height, 0px) + 28px)',
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <Avatar
              src={require('../assets/img/steven_cartoon.JPG')}
              alt="Steven Logo"
              sx={{
                width: 30,
                height: 30,
                mr: 1,
                overflow: 'hidden',
              }}
            />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button component={Link} to="/" variant="text" color="info" size="small" sx={{ minWidth: 0 }}>
                {translations[language].home || "Home"}
              </Button>
              <Button component={Link} to="/notes" variant="text" color="info" size="small" sx={{ minWidth: 0 }}>
                {translations[language].notes || "Notes"}
              </Button>
              <Button component={Link} to="/blogs" variant="text" color="info" size="small" sx={{ minWidth: 0 }}>
                {translations[language].blogs || "Blogs"}
              </Button>
              <Button component={Link} to="/projects" variant="text" color="info" size="small" sx={{ minWidth: 0 }}>
                {translations[language].projects || "Projects"}
              </Button>
              <Button component={Link} to="/fin" variant="text" color="info" size="small" sx={{ minWidth: 0 }}>
                {translations[language].finance || "Finance"}
              </Button>
              <Button component={Link} to="/profile" variant="text" color="info" size="small" sx={{ minWidth: 0 }}>
                {translations[language].profile || "Profile"}
              </Button>
            </Box>
          </Box>

          <Box sx={{ 
            display: { xs: 'none', md: 'flex'}, 
            gap: 0, 
            alignItems: 'center',
          }}>
            <LanguageSwitchComponent onClick={toggleLanguage} />
            <ThemeSwitch onClick={toggleTheme} />
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 1.5, alignItems: 'center' }}>
            {userStore.state.userId === 0 ? (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={handleLoginOpen} // 打开登录模态框
                >
                  {translations[language].login || "Log in"}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={handleSignupOpen} // 打开注册模态框
                >
                  {translations[language].signup || "Sign up"}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={(e) => setAnchorEl(e.currentTarget)} // 打开下拉菜单
                  sx={{ textTransform: 'none'}} // 禁用全大写并添加左侧间距
                >
                  {userStore.state.username}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  sx={{ ml: 0 }} // 添加左侧间距
                >
                  <MenuItem onClick={() => console.log('Navigate to reset password')}>
                    {translations[language].password_reset || "Reset Password"}
                  </MenuItem>
                  <MenuItem
                    onClick={async () => {
                      await userService.getNonuserJWT(); // 执行退出逻辑
                      setAnchorEl(null); // 关闭下拉菜单
                    }}
                  >
                    {translations[language].logout || 'Log out'}
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>

          {/* 移动端菜单 */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1 }} >
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)} >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="top"
              open={open}
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: { top: 'var(--template-frame-height, 0px)' },
              }}
            >
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <MenuItem component={Link} to="/">
                  {translations[language].home || "Home"}
                </MenuItem>
                <MenuItem component={Link} to="/notes">
                  {translations[language].notes || "Notes"}
                </MenuItem>
                <MenuItem component={Link} to="/blogs">
                  {translations[language].blogs || "Blogs"}
                </MenuItem>
                <MenuItem component={Link} to="/projects">
                  {translations[language].projects || "Projects"}
                </MenuItem>
                <MenuItem component={Link} to="/fin">
                  {translations[language].finance || "Finance"}
                </MenuItem>
                <MenuItem component={Link} to="/profile">
                  {translations[language].profile || "Profile"}
                </MenuItem>
                <Divider sx={{ my: 3 }} />
                <MenuItem>
                  <LanguageSwitchComponent onClick={toggleLanguage} />
                </MenuItem>
                <MenuItem>
                  <ThemeSwitch onClick={toggleTheme} />
                </MenuItem>
                <Divider sx={{ my: 3 }} />

                {userStore.state.userId === 0 ? ( // 判断未登录
                  <>
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={handleLoginOpen} // 打开登录模态框
                      >
                        {translations[language].login || "Log in"}
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={handleSignupOpen} // 打开注册模态框
                      >
                        {translations[language].signup || "Sign up"}
                      </Button>
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={() => console.log('Navigate to reset password')} // 打开重置密码逻辑
                      >
                        {translations[language].password_reset || "Reset Password"}
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={async () => {
                          userService.getNonuserJWT(); // 执行退出逻辑
                          setOpen(false)
                        }}
                      >
                        {translations[language].logout || 'Log out'}
                      </Button>
                    </MenuItem>
                    <Divider sx={{ my: 1 }} />
                    <MenuItem>
                      <Button
                        color="info"
                        fullWidth
                        variant="text"
                        disabled // 仅展示用户名
                        sx={{ 
                          textTransform: 'none',
                        }}
                      >
                        {userStore.state.username}
                      </Button>
                    </MenuItem>
                  </>
                )}

              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>

      {/* 登录模态框 */}
      <Modal
        open={loginOpen}
        onClose={handleLoginClose} // 点击外部区域触发关闭
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: 'rgba(0, 0, 0, 0.5)', backdropFilter: 'blur(5px)', },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: 450,
          }}
          onClick={(e) => e.stopPropagation()} // 阻止事件冒泡，防止触发关闭
        >
        <LoginForm
          onClose={handleLoginClose}
          onSignupClick={() => {
            handleLoginClose(); // 关闭登录窗口
            handleSignupOpen(); // 打开注册窗口
          }}
        />
        </Box>
      </Modal>

      {/* 注册模态框 */}
      <Modal
        open={signupOpen}
        onClose={handleSignupClose} // 点击外部区域触发关闭
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: 'rgba(0, 0, 0, 0.5)', backdropFilter: 'blur(5px)', },
        }}

      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {md: '100%', xs: "80%"},
            maxWidth: 450,
          }}
          onClick={(e) => e.stopPropagation()} // 阻止事件冒泡，防止触发关闭
        >
          <SignupForm 
            onClose={handleSignupClose} 
            onLoginClick={() => {
              handleSignupClose(); // 关闭登录窗口
              handleLoginOpen(); // 打开注册窗口
            }}
          />
        </Box>
      </Modal>

    </AppBar>
  );
}
